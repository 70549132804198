/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */

let _worker: Worker | null = null;
let _isWorkerHost = false;
window.gmb = window.gmb || {};
Object.defineProperties(window.gmb, {
  workerHost: {
    get() {
      return _isWorkerHost;
    },
    set(isWorkerHost) {
      _isWorkerHost = isWorkerHost;
    },
  },
  socketWorker: {
    get() {
      if (!_isWorkerHost) {
        return null;
      }
      if (_worker == null) {
        _worker = new Worker(new URL('./pubsub/workers/socket.worker', import.meta.url), { type: 'module' });
      }
      return _worker;
    },
  },
});
