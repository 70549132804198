import { BroadcastChannel } from 'broadcast-channel';

type Listener = (message: PayloadType) => void;
type Listeners = Map<MessageType, Set<Listener>>;
type MessageType = 'login' | 'logout' | 'impersonate' | 'impersonate-end';

type LoginPayload = {
  type: 'login';
};
type LogoutPayload = {
  type: 'logout';
};
type ImpersonatePayload = {
  type: 'impersonate';
};
type ImpersonateEndPayload = {
  type: 'impersonate-end';
  redirect: string;
};

type PayloadType = LoginPayload | LogoutPayload | ImpersonatePayload | ImpersonateEndPayload;

/**
 * Used to keep the authentication state in sync across multiple tabs.
 * @example
 * ```
 * const authProxy = AuthProxy.getInstance();
 * authProxy.on('login', () => { console.log('The user is now logged in.'); });
 * authProxy.on('logout', () => { console.log('The user is now logged out.'); });
 * ```
 */
export default class AuthProxy {
  private static instance: AuthProxy;

  public static getInstance(): AuthProxy {
    if (!AuthProxy.instance) {
      AuthProxy.instance = new AuthProxy();
    }
    return AuthProxy.instance;
  }

  public channel: BroadcastChannel<PayloadType> | null = null;

  private constructor() {
    if (typeof window !== 'undefined') {
      this.channel = new BroadcastChannel('authSync', { webWorkerSupport: false });
      this.channel.addEventListener('message', this.onMessage);
    }
  }

  private onMessage = (payload: PayloadType) => {
    this.listeners.get(payload.type)?.forEach((fn) => fn(payload));
  };

  public broadcast(payload: PayloadType) {
    this.channel?.postMessage(payload);
    this.onMessage(payload);
  }

  private listeners: Listeners = new Map();

  public on(type: 'login', fn: (payload: LoginPayload) => void): void;
  public on(type: 'logout', fn: (payload: LogoutPayload) => void): void;
  public on(type: 'impersonate', fn: (payload: ImpersonatePayload) => void): void;
  public on(type: 'impersonate-end', fn: (payload: ImpersonateEndPayload) => void): void;
  public on(type: MessageType, fn: (payload: any) => void): void {
    if (!this.listeners.has(type)) {
      this.listeners.set(type, new Set());
    }
    this.listeners.get(type)?.add(fn);
  }
}

/**
 * Inform other tabs that the user has logged in.
 */
export const broadcastLogin = (): void => {
  AuthProxy.getInstance().broadcast({ type: 'login' });
};

/**
 * Inform other tabs that the user has logged out.
 */
export const broadcastLogout = (): void => {
  AuthProxy.getInstance().broadcast({ type: 'logout' });
};

/**
 * Inform other tabs that the session is an impersonation session.
 */
export const broadcastImpersonate = (): void => {
  AuthProxy.getInstance().broadcast({ type: 'impersonate' });
};

/**
 * Inform other tabs that the session is an impersonation session has ended.
 */
export const broadcastImpersonateEnd = (redirect: string): void => {
  AuthProxy.getInstance().broadcast({
    type: 'impersonate-end',
    redirect,
  });
};
